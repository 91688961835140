@import '~@adverton/common/src/styles/bootstrap';
@import '~@adverton/common/src/styles/common';
@import '~@adverton/common/src/styles/intercom';

body {
  background: rgba(241, 243, 246, 1) !important;
  margin: 0;
  padding: 0;
}

.option-card {
  border-radius: 0.625rem;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}
.fs-5\.5 {
  font-size: 1.125rem;
}
.p-3\.3 {
  padding: 1.25rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-2\.5 {
  gap: 0.75rem;
}
