html.intercom-mobile-messenger-active[lang],
html.intercom-mobile-messenger-active[lang] > body,
html.intercom-modal-open[lang] {
  overflow: visible !important;
}
.intercom-namespace .intercom-messenger-frame {
  max-height: 100svh !important;
}
@media (max-width: 450px) {
  .intercom-namespace .intercom-messenger-frame {
    bottom: 56px !important;
    max-height: calc(100svh - 56px) !important;
  }
}
